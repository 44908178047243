import { useNavigate, useParams } from 'react-router-dom';
import { getSingleEvent, selectEvent } from '@/stores/events';
import { useAppDispatch } from '@/common';
import { store } from '@/stores';
import { useEffect, useState } from 'react';
import Spinner from '@/modules/components/spinner';

// Cannot link directly to events, because firstly, we might not have the event in the redux store, and secondly,
// we do not know what path an event should be loaded in (archived/reviewed and unarchived/non-reviewed events are loaded in different paths/components).
// This component fetches an event by id, puts it in the store, determines if it is reviewed or not, then routes to the events or archive path as appropriate.
export const ResolveEvent = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { siteId, eventId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadEvent = async (): Promise<void> => {
      if (siteId !== undefined && eventId !== undefined) {
        await dispatch(getSingleEvent({ siteId, eventId }));
        const state = store.getState();
        const event = selectEvent(eventId)(state);

        const path = event?.reviewed ?? false ? `/admin/${siteId}/monitor/archive/${eventId}` : `/admin/${siteId}/monitor/events/${eventId}`;

        navigate(path);
      } else {
        window.logger.error(`Require both siteId and eventId to resolve event. Found siteId: '${siteId}'. eventId: '${eventId}'`);
      }
      setIsLoading(false);
    };

    void loadEvent();
  }, [dispatch, navigate, siteId, eventId]);

  if (isLoading) {
    return <Spinner />;
  }

  return null;
};
