import { $, useAppDispatch, useAppSelector } from '@/common';
import { selectEvent, selectIsEventsLoading } from '@/stores/events';
import { useParams } from 'react-router-dom';
import Spinner from '../components/spinner';
import { selectSelectedVideo, updateSelectedVideo } from '@/stores/selectedEvent';
import { EventTypesNavigation, TagEventNavigation, ViewSelectedNavigation } from '@/modules/clips/components/navigation';
import Playback from '@/modules/clips/components/playback';
import Video from '@/modules/clips/components/playback/Video';

const Clips = (): JSX.Element => {
  const { siteId, eventId } = useParams();
  const isLoading = useAppSelector(selectIsEventsLoading);
  const event = useAppSelector(selectEvent(eventId));
  const selectedVideo = useAppSelector(selectSelectedVideo);
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={$('flex items-center justify-between')}>
        <EventTypesNavigation>{event?.eventType}</EventTypesNavigation>
        <div className={$('flex flex-1 justify-end gap-x-12')}>
          <ViewSelectedNavigation disabled={selectedVideo.length === 0} />
          <TagEventNavigation to='tag' disabled={selectedVideo.length === 0} />
        </div>
      </div>
      <div className={$('pt-5')}>
        <Spinner hide={!isLoading} />
        <Playback
          style={{ maxHeight: `calc(-200px + 100vh)` }}
          className={$('grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 6xl:grid-cols-5 gap-y-4 gap-x-8 h-auto overflow-y-auto')}
        >
          {event?.videos.map((video) => {
            const isSelected = selectedVideo.includes(video);
            return (
              <Video
                key={video.bucketObjectId}
                src={`${process.env.REACT_APP_PUBLIC_VIDEO_HOST}/${siteId}/raw/${video.bucketObjectId}`}
                className={$('rounded w-full cursor-pointer border-[5px] border-transparent border-rounded', {
                  'border-outline-selected': isSelected,
                })}
                onClick={() => {
                  dispatch(updateSelectedVideo({ eventId: eventId ?? '', video }));
                }}
              />
            );
          })}
        </Playback>
      </div>
    </>
  );
};

export default Clips;
