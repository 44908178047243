import Bootstrap from '@/bootstrap';
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements, RouterProvider, type LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getNextEvents } from '@/stores/events';
import { useAppDispatch, useAppSelector } from '@/common';
import { EventType } from '@/stores/types';
import { ActionCreatorWithPayload, type AsyncThunk } from '@reduxjs/toolkit';
import { setSelectedEvent } from '@/stores/selectedEvent';
import Review from '@/modules/events/review';
import Archive from '@/modules/events/archive';
import SelectedClips from '@/modules/clips/selectedClips';
import Clips from '@/modules/clips';
import TagClips from '@/modules/clips/tagClips';
import { getTags } from '@/stores/tags';
import { getContentAccess } from '@/stores/siteConfigs';
import { ResolveEvent } from './resolvers/ResolveEvent';

const AppRouter = (): JSX.Element => {
  const dispatch = useAppDispatch();

  // Generic function to dispatch actions with path params
  const dispatchActions =
    (...actions: Array<AsyncThunk<any, any, any> | ActionCreatorWithPayload<any>>) =>
    async ({ params }: LoaderFunctionArgs<any>): Promise<number> => {
      actions.forEach((action) => {
        dispatch(action({ ...params }));
      });

      return 0;
    };

  const getContentTokenLoader = async ({ params }: LoaderFunctionArgs<any>) => {
    const siteId = params.siteId ?? '';

    await dispatch(getContentAccess({ siteId }));

    return null;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/admin/:siteId/monitor' loader={getContentTokenLoader} element={<Bootstrap />}>
        <Route index element={<Navigate to='events' />} />
        <Route path='events' element={<Outlet />}>
          <Route
            index
            element={<Review />}
            loader={async ({ params }) => {
              dispatch(getNextEvents({ siteId: params.siteId ?? '', eventType: EventType.REVIEW }));
              return 0;
            }}
          />
          <Route path=':eventId' element={<Outlet />}>
            <Route path='resolve' element={<ResolveEvent />} />
            <Route index element={<Clips />} loader={dispatchActions(setSelectedEvent)} />
            <Route path='preview' element={<SelectedClips />} />
            <Route path='tag' element={<TagClips />} loader={dispatchActions(getTags)} />
          </Route>
        </Route>
        <Route path='archive' element={<Outlet />}>
          <Route
            index
            element={<Archive />}
            loader={async ({ params }) => {
              dispatch(getNextEvents({ siteId: params.siteId ?? '', eventType: EventType.ARCHIVE }));
              return 0;
            }}
          />
          <Route path=':eventId' element={<TagClips />} loader={dispatchActions(getTags, setSelectedEvent)} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
